<template>
  <div class="tablebody">
    <div class="info">
      <div class="paycount" id="paycount1">{{totalRows}}</div>
      <b-tooltip target="paycount1">Количество терминалов</b-tooltip>
      <b-btn class='paycount' size="sm" v-on:click="querytabledata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid class="justify-content-md-center">
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Поиск" size="sm" style="background-color: #c6ced4" />
              <b-input-group-append>
                <b-btn size="sm" :disabled="!filter" @click="filter = ''"><b-icon-backspace></b-icon-backspace></b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" class="my-1">
          <b-input-group v-if="isOwner||isBank">
            <b-form-select v-model="dealer" :options="dealers" size="sm" style="background-color: #c6ced4" >
              <template slot="first">
                <option :value="null" >-- Дилер --</option>
              </template>
            </b-form-select>
            <b-btn size="sm" :disabled="!dealer" @click="dealer = null" ><b-icon-backspace></b-icon-backspace></b-btn>
          </b-input-group>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
                table-variant="dark"
                style="color: #ccc"
                stacked="md"
                :items="filterItems"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
      >
      <template v-slot:cell(status)="data">
        <b-icon-check-circle style="color: green" v-if="!data.item.status"></b-icon-check-circle>
        <b-icon-dash-circle-fill style="color: #ff4a46" v-if="data.item.status"></b-icon-dash-circle-fill>
      </template>
      <template v-slot:cell(actions)="data">

        <b-btn size="sm" @click="getPassword(data.item.id)" v-if="isOwner&!isGuest" style="margin: 0 4px 0 0"> <b-icon-three-dots style="width: 15px; height: 15px;"></b-icon-three-dots> </b-btn>
        <b-btn size="sm" @click="updateTerminal(data.item.id,'disable', !data.item.disabled)" v-if="!data.item.disabled&isOwner&!isGuest" style="margin: 0 4px 0 0"> <b-icon-unlock style="width: 15px; height: 15px;"> </b-icon-unlock> </b-btn>
        <b-btn size="sm" @click="updateTerminal(data.item.id,'enable', !data.item.disabled)" v-if="data.item.disabled&isOwner&!isGuest" style="margin: 0 4px 0 0"> <b-icon-lock style="width: 15px; height: 15px;"> </b-icon-lock> </b-btn>
        <b-btn size="sm" @click="updateTerminal(data.item.id,'delete')" v-if="isOwner&!isGuest" style="margin: 0"> <b-icon-backspace style="width: 15px; height: 15px;"></b-icon-backspace> </b-btn>
      </template>
      </b-table>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination pagination-sm :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'suppliers',
  data: () =>  ({
    isBusy: false,
    items: [],
    fields: [
      //name,title,address,bik,inn,okpo,phone
      { label: 'ID', key: "id", sortable: true, 'class': 'text-left' },
      { label: 'Дилер', key: "dealer", sortable: true, 'class': 'text-left' },
      { label: 'Терминал', key: "name", 'class': 'text-left' },
      { label: 'Адрес', key: "address", 'class': 'text-left' },
      { label: 'login', key: "login", 'class': 'text-left' },
      { label: 'Статус', key: "status", 'class': 'text-center' },
      { label: 'Действия', key: 'actions', 'class': 'text-center' }
      //{ label: 'ОКПО', key: "okpo", 'class': 'text-left' },
      //{ label: 'Контакный номер', key: "phone", 'class': 'text-left' }
    ],
    currentPage: 1,
    perPage: 40,
    totalRows: 0,
    totalsum: 0,
    pageOptions: [ 20, 40, 80 ],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    dealer: null,
    dealers: [],
  }),
  computed: {
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isGuest () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='guest'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    filterItems () {
      const filteredItems = this.items.filter(item => ((this.dealer==null ? this.dealer: item.dealerid)==this.dealer));
      this.onFiltered (filteredItems);
      return filteredItems
    },
  },

  methods: {

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getDealers () {
      axios
      .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                    partnertype: this.$store.state.authentication.user.partnertype,
                                                    bankId: '',
                                                    type: ['Dealers'], status: false,
                                                    token: this.$store.state.authentication.user.token})
      .then(response => {
        this.dealers = response.data;
        this.dealers.forEach((item,index) => {
          Object.assign(this.dealers[index], {value:item.id}, {text: item.name})
        })
      })
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert(error)
      });
    },

    querytabledata () {
      axios
          .post(`${config.apiUrl}/query/getdata`,{ DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                  type: ['Terminals'], partnertype: this.$store.state.authentication.user.partnertype,
                                                  token: this.$store.state.authentication.user.token })
          .then(response => {
                  this.items = response.data;
                  this.totalRows = this.items.length
                })
          .catch(e => {alert (e); return [e]})
    },
    getPassword (id) {
      axios
          .post(`${config.apiUrl}/query/getdata`,{ DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                  type: ['getTerminalPassword'], terminalId: id,
                                                  token: this.$store.state.authentication.user.token })
          .then(response => {
                  alert(response.data[0].password);
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert (error)
          });
    },
    updateTerminal (id,action,state) {
      if (confirm("Вы уверены?")) {
        axios
          .post(`${config.apiUrl}/query/getdata`,{ DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                  type: ['updateTerminal'], terminalId: id, action: action, state: state,
                                                  token: this.$store.state.authentication.user.token })
          .then(
            this.querytabledata()
                )
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert (error)
        });
      }
    }
  },
  created() {
    this.querytabledata ()
    this.getDealers ()
  }
}
//<b-table striped hover :items="items" :fields="fields"></b-table>
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
